import baseDomainPath from "@/common/baseDomainPath";
let ThemeDetail = null;
if (baseDomainPath.indexOf("ivy-way") > -1) {
  ThemeDetail = {
    themeColor: "#42a16a",
    logo: "https://ivy-way.com/img/logo.png",
    image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
    icon: "IvyWayfavicon.ico",
    name: "Ivy-Way",
    tabName: "Ivy-Way",
    linkArr: [
      {
        link: "https://ivy-way.com/about",
        name: "about"
      },
      {
        link: "https://ivy-way.com/instructors",
        name: "instructors"
      },
      {
        link: "https://ivy-way.com/classes",
        name: "classes"
      },
      {
        link: "https://ivy-way.com/stories",
        name: "stories",
        icon: "fas fa-user-graduate"
      },
      {
        link: "https://ivy-way.com/blogs",
        name: "blogs"
      }
    ]
  };
} else if (baseDomainPath.indexOf("primerica") > -1) {
  ThemeDetail = {
    themeColor: "#d5a322",
    logo: "https://www.primerica.com.tw/img/logo.fd36bf6b.png",
    image: "https://www.primerica.com.tw/img/logo.fd36bf6b.png",
    icon: "faviconPrimerica.ico",
    name: "Primerica Institute",
    tabName: "Primerica Institute",
    isDarkBackground: true,
    isCompany: true,
    linkArr: [
      {
        link: "https://www.primerica.com.tw/ ",
        name: "home"
      }
    ]
  };
} else {
  ThemeDetail = {
    themeColor: "#00aee7",
    logo: null,
    image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
    icon: "SATfavicon.ico",
    name: "SAT",
    tabName: "APMocks",
    linkArr: []
  };
}

// ThemeDetail = {
//   themeColor: "#d5a322",
//   logo: "https://www.primerica.com.tw/img/logo.fd36bf6b.png",
//   image: "https://www.primerica.com.tw/img/logo.fd36bf6b.png",
//   icon: "faviconPrimerica.ico",
//   name: "Primerica Institute",
//   tabName: "Primerica Institute",
//   isDarkBackground: true,
//   isCompany: true,
//   linkArr: [
//     {
//       link: "https://www.primerica.com.tw/ ",
//       name: "home"
//     }
//   ]
// };

export default ThemeDetail;
